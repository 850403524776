p,
span,
li,
h1,
h2,
th,
td {
  font-family: 'Volvo Novum', sans-serif;
  font-size: 14px;
}

h1,
h2 {
  text-transform: uppercase;
  font-weight: normal;
}

h1 {
  margin-top: 0;
  font-size: 24px;
  font-weight: 500;
}

h2 {
  font-size: 20px;
}
